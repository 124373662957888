import { NgModule } from '@angular/core';
import { Routes, RouterModule, LoadChildren } from '@angular/router';
import { NotFoundComponent, MainContainerComponent, EntryPageComponent, EmptyContainerComponent, BaseRoutes } from '@nts/std';
import { environment } from 'src/environments/environment';

const title = environment ? environment.appTitle : 'Application Title';

const routes: Routes = [
  // #region rotte con autenticazione
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      ...BaseRoutes.getEntityRoutes(
        title,
        'businessdataconfiguration',
        () => import('./business-data-configuration/business-data-configuration.module').then(m => m.BusinessDataConfigurationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'handcraftdataconfiguration',
        () => import('./handcraft-data-configuration/handcraft-data-configuration.module').then(m => m.HandcraftDataConfigurationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'scenario',
        () => import('./scenario/scenario.module').then(m => m.ScenarioModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'scenariodefinition',
        () => import('./scenario-definition/scenario-definition.module').then(m => m.ScenarioDefinitionModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'transformationmanager',
        () => import('./transformation-manager/transformation-manager.module').then(m => m.TransformationManagerModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'dashboardconfiguration',
        () => import('./dashboard-configuration/dashboard-configuration.module').then(m => m.DashboardConfigurationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'transformationconfiguration',
        () => import('./transformation-configuration/transformation-configuration.module').then(m => m.TransformationConfigurationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'datatransformerlauncher',
        () => import('./data-transformer-launcher/data-transformer-launcher.module').then(m => m.DataTransformerLauncherModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'storepublisher',
        () => import('./store-publisher/store-publisher.module').then(m => m.StorePublisherModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'storepublisheruploader',
        () => import('./store-publisher-uploader/store-publisher-uploader.module').then(m => m.StorePublisherUploaderModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'storeconsumer',
        () => import('./store-consumer/store-consumer.module').then(m => m.StoreConsumerModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'storeconsumerdownloader',
        () => import('./store-consumer-downloader/store-consumer-downloader.module').then(m => m.StoreConsumerDownloaderModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'userparameter',
        () => import('./user-parameter/user-parameter.module').then(m => m.UserParameterModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'nctdataconfiguration',
        () => import('./nct-data-configuration/nct-data-configuration.module').then(m => m.NCTDataConfigurationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'datadispatcherlauncher',
        () => import('./data-dispatcher-launcher/data-dispatcher-launcher.module').then(m => m.DataDispatcherLauncherModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'datadispatcherconfiguration',
        () => import('./data-dispatcher-configuration/data-dispatcher-configuration.module').then(m => m.DataDispatcherConfigurationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'unionconfiguration',
        () => import('./union-configuration/union-configuration.module').then(m => m.UnionConfigurationModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'unionmanager',
        () => import('./union-manager/union-manager.module').then(m => m.UnionManagerModule)
      ),
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      )
    ]
  ),
  // #endregion rotte con autenticazione

  // #region rotte con autenticazione
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      {
        path: 'manage/dashboarddesigner',
        loadChildren: () => import('@nts/dashboard-designer-aggregate').then(m => m.DashboardDesignerAggregateModule)
      },
    ],

    // Passando null evito di impostare il container
    // Utile quando il modulo ha già le sue logiche tra cui il container
    null
  ),

  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      {
        path: 'manage/dashboardviewer',
        loadChildren: () => import('@nts/dashboard-viewer-aggregate').then(m => m.DashboardViewerAggregateModule)
      },
    ],
    () => import('@nts/std').then(mod => mod.EmptyContainerWithToastComponent),
  ),
  // #endregion rotte con autenticazione

  // rotte base per la gestione degli errori
  ...BaseRoutes.getBaseErrorsRoute()

]

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
